import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './logo.png';
import "./ErrorPage.css";

function ErrorPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setShowContent(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleHomeButtonClick = () => {
    // Navigate to the home page
    navigate('/');
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      {loading ? (
        <div className='loading'>
        <span className='loader'></span></div>
      ) : (
        <>
          {showContent && (
            <div style={{ textAlign: 'center', padding: '20px' }}>
        <img className='logo' src={logo} alt="Logo"></img>
      <h1 style={{ color: 'red' }}>404 - Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
     
      <button className='go-to-home' onClick={handleHomeButtonClick}>Go to Home</button>
      
    </div>
          )}
        </>
      )}
    </div>
  );
}

export default ErrorPage;
