import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import img1 from "./images/img1.jpg";
import img2 from "./images/img2.jpg";
import img3 from "./images/img3.jpg";
import img4 from "./images/img4.jpg";
import quality from "./images/quality.png";
import logo1 from "./logo1.png";
import { Helmet } from 'react-helmet';


const LandingPage = () => {
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [img1, img2, img3, img4];
  const [rootPaths, setRootPaths] = useState([]); // State to hold all root path names
  const [pageData, setPageData] = useState({
    rootPath: '',
    title: '',
    metaDescription: ''
  });
  
  const handleLinkClick = () => {
    // Navigate to CertificateValidator without a specific certificateId
    navigate('/validate');
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    // Set up an interval to change the image every 2 seconds
    const intervalId = setInterval(() => {
      nextImage();
    }, 3000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [currentImageIndex]);

  const getTransformValue = () => {
    return `translateX(${-currentImageIndex * 100}%)`;
  };

const handleGetNowClick = () => {
  const receiverEmail = 'info@iqaco.com';
  const subject = 'Regarding ISO Certification';
  const body = '';
  
  // Constructing the mailto link
  const mailtoLink = `mailto:${receiverEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  // Open the mailto link
  window.open(mailtoLink);
};

  return (
    
    <div className="landing-page">
      <header className="header">
      </header>
      <div className='land-content'>
         <div className='logo-cont'>
        <img className='logo2' src={logo1} alt="Logo"></img></div>
      <p>
        <p className='iqaco'>International Quality Assurance Co </p>
        <h1 className='title'>The Mark of Excellence</h1>
      </p>
    <div className="contact-section">
        <h>Contact us to get your ISO Today</h><br></br><br></br>
        <button onClick={handleGetNowClick} className="get-now-button">Get Now</button>
      </div>
      
     

      <section className="main-content">
        <p>
          Are you looking for a reliable partner to ensure the quality of your products and services in the international market?
          Do you want to reduce the risks and costs associated with poor quality, compliance issues, and customer dissatisfaction?
          If so, you have come to the right place.
        </p>
         
        <div className='slider-container'>
          <div className='slider' style={{ transform: getTransformValue() }}>
            {images.map((image, index) => (
              <img key={index} src={image} alt={`Slider Image ${index + 1}`} />
            ))}
          </div>
        </div>
        <br></br>
        <p className='button-top-text'>Validate your IQACO certificate here </p>
      <div>
        <button onClick={handleLinkClick} className="cta-button">
          <Link to="/validate" className="cta-link">
            Validate
          </Link>
        </button>
      </div>
      <br></br>
        <p>
          Whether you need to test your software, hardware, or systems, certify your products or processes, or improve your quality
          management and performance.
        </p>

        
          <p className='card'>
            <strong className='c1'>Software Quality Assurance<br></br><br></br></strong> We test your software applications and systems for functionality,
            usability, security, performance, compatibility, and compliance using manual and automated methods, as well as AI and DevOps techniques.
          </p>
          <p className='card'>
            <strong className='c1'>Hardware Quality Assurance<br></br><br></br></strong> We test your hardware devices and components for reliability, durability, safety,
            and functionality using state-of-the-art equipment and facilities.
          </p>
          <p className='card'>
            <strong className='c1'>Certification Services<br></br><br></br></strong> We certify your products or processes according to international standards and regulations. We also provide auditing, inspection, and verification services to ensure your compliance and quality assurance.
          </p>
          <p className='card'>
            <strong className='c1'>Quality Consulting Services<br></br><br></br></strong> We help you improve your quality management and performance by providing strategic advice,
            training, coaching, and support. We also help you implement quality improvement initiatives.
          </p>
      

        <p>
          We work with you closely to understand your needs, expectations, and challenges, and deliver customized and cost-effective solutions that meet or exceed your quality standards.
        </p>
        <div className='quality-container'>
<img className='quality' src={quality} alt="Logo"></img></div>
        <p>
          We are committed to providing you with the highest level of quality assurance and customer satisfaction. We are your trusted partner in achieving quality excellence
          and success in the global market.
        </p>

        <p>Contact us today to find out how we can help you with your quality assurance needs.</p>
      </section>
</div>
      <footer className="footer1">
        
        <p className='footer'>&copy; International Quality Assurance Co. All rights reserved.</p>
        
      </footer>
      </div>
    
  );
};

export default LandingPage;

window.addEventListener('scroll', function() {
  var logo = document.querySelector('.logo2');
  if (logo) {
    if (window.scrollY > 1) {
      logo.classList.add('hide-logo');
    } else {
      logo.classList.remove('hide-logo');
    }
  }
});

