import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route, BrowserRouter as Router, Routes, useNavigate, useParams } from 'react-router-dom';
import ErrorPage from "./ErrorPage";
import LandingPage from './LandingPage';
import logo from "./logo.png";
import ErrorPage1 from './sitemap';
const CertificateValidator = ({ certificateData, setCertificateData }) => {
  const [certificateId, setCertificateId] = useState('');  // Add this line
  const [isValid, setIsValid] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const navigate = useNavigate();

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      validateCertificate();
    }
  };

 const validateCertificate = () => {
  if (!certificateId) {
    setErrorMessage('Please enter the ID');
    return;
  }

  setErrorMessage(null);

  const fetchCertificate = async () => {
      try {
        const response = await fetch('https://opensheet.elk.sh/1OLaPVo1wkvdCDa0rAtDgGYMxHQ5yPBshg9PKwM2ClPM/1');
        if (!response.ok) {
          throw new Error('Failed to fetch data from the API');
        }
        const data = await response.json();

        const certificateFromApi = data.find(cert => cert.id === certificateId);
        setCertificate(certificateFromApi);

        if (certificateFromApi) {
          setIsValid(certificateFromApi.isValid);
          if (!certificateFromApi.isValid) {
            setShowErrorPopup(true);
          } else {
            setShowErrorPopup(false);
            navigate(`/validate/${certificateId}`);
          }
        }
        else {
          setIsValid(false);
          setShowErrorPopup(true);
        }
      } catch (error) {
        console.error(error);
        // Handle error state if needed
      }
    };

    fetchCertificate();
  };
  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        const response = await fetch('https://opensheet.elk.sh/1OLaPVo1wkvdCDa0rAtDgGYMxHQ5yPBshg9PKwM2ClPM/1');
        if (!response.ok) {
          throw new Error('Failed to fetch data from the API');
        }
        const data = await response.json();
                                
        const certificateFromApi = data.find(cert => cert.id === certificateId.toUpperCase());
        setCertificate(certificateFromApi);
      } catch (error) {
        console.error(error);
        // Handle error state if needed
      }
    };

    fetchCertificate();
  }, [certificateId]);
 const handleHomeButtonClick = () => {
    // Navigate to the home page
    navigate('/');
  };

  return (
    <div className='validate'>
      <img className='logo1' src={logo} alt="Logo"></img>
      <p></p>
      <label>
        Enter Certificate ID:
        <input
  type="text"
  value={certificateId}
  onChange={(e) => setCertificateId(e.target.value.toUpperCase())}
  onKeyDown={handleEnterKey}
/>
      </label>

      {errorMessage && <p className='error'>{errorMessage}</p>}

      <button onClick={validateCertificate}>Validate</button>

      {/* Error Popup */}
      <ErrorPopup
        show={showErrorPopup}
        onHide={() => setShowErrorPopup(false)}
      /><br></br><br></br>
          <a href='' onClick={handleHomeButtonClick}>Go to Home Page</a><br></br><br></br>
    </div>
    
  );
};
 

const ErrorPopup = ({ show, onHide }) => {
  return (
    show && (
      <div className="modal-overlay">
        <div className="modal">
          <div className="custom-error-modal">
            <div className="custom-error-modal-content">
              <p className="error1 custom-error-modal-body">The certificate is invalid.</p>
              <button onClick={onHide} className="custom-error-modal-button">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};




const CertificateDetail = () => {
  const { certificateId } = useParams();
  const [certificate, setCertificate] = useState(null);
  const [showErrorPage, setShowErrorPage] = useState(false);
    const navigate = useNavigate();
  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        const response = await fetch('https://opensheet.elk.sh/1OLaPVo1wkvdCDa0rAtDgGYMxHQ5yPBshg9PKwM2ClPM/1');
        if (!response.ok) {
          throw new Error('Failed to fetch data from the API');
        }
        const data = await response.json();
        
        const certificateFromApi = data.find(cert => cert.id === certificateId);
        setCertificate(certificateFromApi);

        // Add a 2-second delay before showing the error page
        if (!certificateFromApi) {
          setTimeout(() => {
            setShowErrorPage(true);
          }, 1000);
        }
      } catch (error) {
        console.error(error);
        // Handle error state if needed
      }
    };

    fetchCertificate();
  }, [certificateId]);

  if (showErrorPage) {
    return <ErrorPage />;
  }

  if (!certificate) {
    return null; // Return null while waiting for the API response
  }

const handleHomeButtonClick = () => {
  // Go back to the previous route
  window.history.back();
};

const handleHomeButtonClick1 = () => {
  // Go back to the previous route
 navigate('/');
};
  return (
     <div className='cert-detail' >
      <img className='logo' src={logo} alt="Logo"></img>
      {certificate.img && <img className="image" src={certificate.img} alt="img" />}
      <h4 className='cid'>Certificate no: {certificate.id}</h4>
      <p>{certificate.name}</p>
      <p className='remarks'>{certificate.remarks}</p>
                <button onClick={handleHomeButtonClick}>Validate Another Certificate</button>  <br></br><br></br>
       <a href='' onClick={handleHomeButtonClick1}>Go to Home Page</a>
    </div>
  );
};


function App() {
  const [certificateData, setCertificateData] = useState(null);
  const [rootPaths, setRootPaths] = useState([]); // State to hold all root path names
  const [titles, setTitles] = useState([]); // State to hold all titles
  const [metaDescriptions, setMetaDescriptions] = useState([]); // State to hold all titles

  // Function to fetch all data from the Google Sheet link
  const fetchData = async () => {
    try {
      const response = await fetch('https://opensheet.elk.sh/1MKDP-5gQdLrghvcaIIp0K2DSoqB_qsZUcJ9kMR04gzw/1');
      if (!response.ok) {
        throw new Error('Failed to fetch data from the API');
      }
      const data = await response.json();

      // Extract all rootPath and title values from the fetched data
      const allRootPaths = data.map(item => item.rootPath);
      const allTitles = data.map(item => item.title);
      const allMetaDescriptions = data.map(item => item.metaDescription);
      // Set the state with all rootPath and title values
      setRootPaths(allRootPaths);
      setTitles(allTitles);
      setMetaDescriptions(allMetaDescriptions);
    } catch (error) {
      console.error(error);
      // Handle error state if needed
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

const Home = ({ rootPath }) => {
  const currentRootPathTitles = titles.filter((title, index, ) => rootPaths[index] === rootPath);
const currentmetaDescriptions = metaDescriptions.filter((metaDescription, index, ) => rootPaths[index] === rootPath);
  return (
    <div>
      <>
         <Helmet>
        {currentRootPathTitles.map((title, index) => (
          <title key={index}>{title}</title>
        ))}
        {currentmetaDescriptions.map((metaDescription, index) => (
          <meta key={index} name="description" content={metaDescription} />
        ))}
      </Helmet>
      </>
      <LandingPage />
    </div>
  );
};


  return (
   <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          {rootPaths.map(rootPath => (
    <Route key={rootPath} path={`/${rootPath}`} element={<Home rootPath={rootPath} />} />
  ))}
          
          <Route path="/validate/:certificateId" element={<CertificateDetail />} />
          <Route path="/validate" element={<CertificateValidator certificateData={certificateData} setCertificateData={setCertificateData} rootPaths={rootPaths} />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/sitemap" element={<ErrorPage1 />} /> {/* Render the sitemap links */}
        </Routes>
      </div>
    </Router>
  );
}
function AppRoutes({ currentPath }) {
  const navigate = useNavigate();

  const handleValidateClick = () => {
    const newPath = `${currentPath}/validate`;
    navigate(newPath);
  };

  return (
    <>
      <CertificateValidator onValidateClick={handleValidateClick} />
      <CertificateDetail />
    </>
  );
}
export default App;