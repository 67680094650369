import "./ErrorPage.css";
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

function ErrorPage1() {
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('https://opensheet.elk.sh/1MKDP-5gQdLrghvcaIIp0K2DSoqB_qsZUcJ9kMR04gzw/1');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setPageData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []); // Empty dependency array to ensure useEffect only runs once

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <div>
        {pageData.map((entry, index) => (
          <a key={index} href={`https://iqaco.com/${entry.rootPath}`} style={{ marginRight: '10px' }}>
            {index + 1}
          </a>
        ))}
      </div>
      <table>
        <thead>
          <tr>
            {/* <th>Root Path</th>
            <th>Title</th>
            <th>Meta Description</th> */}
          </tr>
        </thead>
        <tbody>
          {pageData.map((entry, index) => (
            <tr key={index}>
              {/* <td>{entry.rootPath}</td>
              <td>{entry.title}</td>
              <td>{entry.metaDescription}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ErrorPage1;
